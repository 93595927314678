.notification {
    position: absolute;
    width: 30em;
    top: 5;
    left: 35%;
    padding: 10px;
    color: white;
    z-index: 100;
    text-align: center;
    top: 3%;
}
.error {
    background: #cc3300;
}

.pending {
    background: #FFCC00
}
.success {
    background: #99cc33
}

.upload-error {
    color: #D8000C; 
    background-color: #FFD2D2; 
    border: 1px solid #D8000C; 
}

.upload-success {
    color: #4F8A10; 
    background-color: #DFF2BF; 
    border: 1px solid #4F8A10; 
}