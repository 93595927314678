.upload-button {
    margin-left: 17px !important;
    color: #ffff !important;
    background: #1792e5 !important;
}

.width-25 {
    width: 25%;
}

.form-field-cont {
    width: 50%;
}

.rs-btn-icon {
    border: 1px solid #d0d0d0 !important;
}

.ui.modal>.content {
    padding: 40px !important;
}

.input-field-header {
    color: #606060;
    font-weight: bold;
  }

.ReactModal__Content > form {
    position: absolute;
    display: flex;
    height: 92%;
    width: 92%;
}

.ReactModal__Content > form input, select, input, textarea {
    height: 30px;
    border-radius: 3px;
    box-shadow: none;
    width: 100%;
    border: 1px solid #e4e2e2;
}

.field-container > div:nth-child(1) {
    padding: 20px 0 5px 0px;
}

.field-container > label {
    margin-right: 5px;
    font-size: 16px;
}

.field-container > span {
    margin-left: 5px;
    color: red;
    font-size: 14px;
}

.group-add-buttons {
    padding: 14px 0;
}

.transac-cont 
.-pageSizeOptions {
    display: none;
   }

.two-fields-row-class {
    width: 47%;
}

.modal-footer {
    border-top: 1px solid #d0d0d0;
}

.form-content {
    display: flex;
    flex-wrap: wrap;
}

