body {
  margin: 0;
  font-family: 'HWBook',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:rgb(51, 51, 51);
  background-color: #f7f7f7;
}
@font-face {
  font-family: 'HWBook';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/HoneywellSansWeb-Book.woff') format('woff');
}
@font-face {
  font-family: 'HWMedium';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/HoneywellSansWeb-Medium.woff') format('woff');
}
@font-face {
  font-family: 'HWBold';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/HoneywellSansWeb-Bold.woff') format('woff');
}
@font-face {
  font-family: 'HWExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/HoneywellSansWeb-ExtraBold.woff') format('woff');
}

a, a:hover, a:visited, a:focus  {
  color: #1792e5;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.white-text {
  color: #fff;
}

.black-text {
  color: black !important;
}

.black-bg {
  background: black;
}

.white-bg {
  background: #fff !important;
}

.btn {
  border: 1px solid #1792e5;
  min-width: 100px;
  min-height: 40px; 
  cursor: pointer;
}

.font-sm {
  font-size: 14px;
}

button:disabled,
button[disabled]{
  opacity: 0.5;
  cursor: not-allowed;
}

.cursor {
  cursor: pointer;
}


.btn-primary {
  background-color: #1792e5;
  color: #fff;
}

.btn-secondary {
  color:  #1792e5;
  background-color: #fff;
}

.p-0 {
  padding: 0px;
}

.p-3 {
  padding: 3px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-3 {
  padding-top: 3px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-3 {
  padding-right: 3px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-3 {
  padding-left: 3px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.m-0 {
  margin: 0px;
}

.m-3 {
  margin: 3px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.h-100 {
  height: 100%;
}

.field {
  height: 30px;
  border-radius: 3px;
  box-shadow: none;
  width: 100%;
  border: 1px solid #e4e2e2;
}

fieldset{
  border: none;
}

.border {
  border : 1px solid #d0d0d0
}

.border-left {
  border-left : 1px solid #d0d0d0
}


.border-right {
  border-right : 1px solid #d0d0d0
}

.rt-table {
  background-color: #fff;
}

.rt-th, .rt-td {
  padding: 15px 10px!important;
  align-self: center;
}

.rt-thead.-header {
  box-shadow: none !important;
}

tr:nth-child(odd){
  background-color: #4C8BF5;
  color: #fff;
}

.rt-tr.-odd {
  background-color: #f8f8f8;
}

.subheaderText {
  color: #707070;
}

.black-text {
  color: #000000;
}

.flat-button {
  margin: 0 !important;
  border-radius: 0 !important;
}

.w-100 {
  width: 100%;
}

.align-items-center {
  display: flex;
  align-items: center;
}